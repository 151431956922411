import { useCookieConfiguration } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useReportError } from '/machinery/ReportError'
import { routeMap } from '/routeMap'
import { Button } from '../buildingBlocks/Button'
import { Heading32 } from '../buildingBlocks/Heading'

import styles from './CookieBar.css'

export function CookieBar({ initialConfiguration, layoutClassName = undefined }) {
  const clientConfig = useClientConfig()
  const reportError = useReportError()

  const {
    configuration,
    acceptAll,
    rejectAll,
  } = useCookieConfiguration({
    availablePermissions: clientConfig.cookieConfiguration.availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  const displayConsent = configuration?.show ?? false

  return displayConsent ? (
    <aside className={cx(styles.component, layoutClassName)}>
      <Heading32 h={3} title="Ook wij gebruiken cookies" />
      <p>
        We gebruiken cookies (en vergelijkbare technieken) op deze website. Dit is nodig om de gegevens die u invult te kunnen bewaren. Uw gegevens zijn altijd anoniem. Ze zijn niet terug te leiden naar u als persoon.
      </p>
      <Actions {...{ acceptAll, rejectAll }} />
    </aside>
  ) : <aside style={{ display: 'none' }} />
}

function Actions({ acceptAll, rejectAll }) {
  return (
    <div className={styles.componentActions}>
      <Button onClick={acceptAll} icon={null} data-x='accept-cookies' className={styles.button}>
        Akkoord
      </Button>
    </div>
  )
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}
